<template>
  <div
    :class="'recharge-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/bg-index.png')" />
      <div class="credit">
        <span class="title">線上課程提交課件</span>
      <!--  <span class="price">{{ agentInfo.Balance }}</span> -->
      </div>
    </div>

    <div class="credit-block">
      <van-form @submit="onSubmit">
       
        <div class="van-cell van-field">
          
          <van-uploader
		    accept=".pdf,.doc,.docx"
            v-model="TradeProof"
            :max-count="1"
			:max-size="5*1024*1024"
            upload-text="上傳課件"
            :after-read="uploadTradeProof"
            class="trade-proof"
          />

		  
          <div
            v-if="showTradeProofMsg"
            class="van-field__error-message"
            style="width: 100%"
          >
            {{ PAGELANG.relestradeproof }}
          </div>
        </div>
        <van-button
          type="primary"
          block
          color="var(--van-card-price-color)"
          native-type="submit"
          class="btn-submit"
          >提交</van-button
        >
      </van-form>
    </div>
  </div>


</template>

<script>
import { showToast, closeToast } from "vant";
import { getCookie } from "../util/index.js";


export default {

  data() {
    return {
      agentInfo: {},
      TradeProof: [],
      showTradeProofMsg: false,
      useraccount: [],
      chargelimit: 0,
      recharge: {},
      PAGELANG: {},
      rules: {},
      labelWidth: "120px",
	  ticketUrls:[],
	  transferUserName:"",
	  textLength: 0,//文字字數
	  goodsTutorId:"",// 导师任务id
	  update:false,//是否被駁回
	  
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
	
	this.axios.get(this.actions.memberInfo).then((response) => {
		  this.transferUserName=response.data.data.realName
	})
	
	this.axios.get(this.actions.teacherSchedule).then((response) => {
		  this.goodsTutorId=response.data.data.goodsTutorId
		  this.id=response.data.data.curriculum.id
		  if(response.data.data.curriculum && response.data.data.curriculum.state==5 ){
						  this.update=true
		  }
	})


	

    this.PAGELANG = this.LANG.pages.recharge;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;

    if (this.LANGCODE != "zh") {
      this.labelWidth = "190px";
    }



  },
  methods: {
	  
   
    uploadTradeProof(file){
      this.showTradeProofMsg = false;
	  const forms = new FormData()
	 console.log(file.file.type); 
	 //  Object.defineProperty(file.file, 'type', {
	 //           writable: true, //设置属性为可写
	 //  })
	 
	 //  file.file.type="application/xlsx"
	  console.log(file);
	  forms.append('file', file.file)
	  this.axios.post(this.actions.uploadFile,forms).then(res=>{
		  console.log(res)
		  this.ticketUrls.push(res.data.data.url)
	  })
	  
    },
    checkAmount(val) {
      console.log(val);
	  
    },

    onSubmit() {
      if (this.TradeProof.length <= 0) {
        this.showTradeProofMsg = true;
        return false;
      }
      this.issubmit = true;
      this.showTradeProofMsg = false;
      
	  let postdata = new Object();
	  postdata.url =this.ticketUrls;
	  postdata.goodsTutorId=this.goodsTutorId
	  postdata.type=2
	  postdata.state=2
	  postdata.id=this.id
	  console.log('postdata',postdata);
	  if(this.update==true){
	  	this.axios.post(this.actions.updateSalonCourseApproval,postdata).then(res=>{
	  		  console.log(res)
	  		  if(res.data.code==200){
	  			  closeToast();
	  			  showToast({
	  			    message:"提交成功,等待审核",
	  			    forbidClick: true,
	  				onClose: () => {
	  				  this.$router.replace({
	  				       path: "/teacherSchedule",
	  				      });
	  				   },
	  			  });
	  			  
	  		  }else{
	  			  closeToast();
	  			  showToast({message:res.data.message})
	  		  }
	  	})
	  }else{
	  	this.axios.post(this.actions.updateSalonCourseApproval,postdata).then(res=>{
	  		  console.log(res)
	  		  if(res.data.code==200){
	  			  closeToast();
	  			  showToast({
	  			    message:"提交成功,等待审核",
	  			    forbidClick: true,
	  				onClose: () => {
	  				  this.$router.replace({
	  				       path: "/teacherSchedule",
	  				      });
	  				   },
	  			  });
	  			  
	  		  }else{
	  			  closeToast();
	  			  showToast({message:res.data.message})
	  		  }
	  	})
	  }
      
    },
  },
};
</script>

<style src="../assets/css/recharge.css" scoped></style>
<style scoped>
.recharge-page:not(.zh) .trade-proof >>> .van-uploader__upload-text {
  line-height: 14px;
  text-align: center;
}
</style>